import { db } from "@spesill/config/firebase";
import {
  DocumentData,
  QueryDocumentSnapshot,
  Timestamp,
} from "@spesill/libs/firebase";
import { Entity } from "@spesill/models/__common__/entity";

type DocumentValueType<T extends ExcludeMethods<Entity>> = ChangeTypeOfKeys<
  Exclude<T, "id">,
  "createdAt" | "updatedAt",
  Timestamp
>;

export abstract class DBRepositoryBase<T extends ExcludeMethods<Entity>> {
  db = db;

  protected docToObject(doc: QueryDocumentSnapshot<DocumentData>): T {
    const data = doc.data() as DocumentValueType<T>;

    let newData = {} as T;

    // すべてのTimestamp型のインスタンスをDateにパースする
    Object.keys(data).map((key) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const value = data[key] as unknown;
      if (value instanceof Timestamp)
        newData = { ...newData, [key]: value.toDate() };
      else return (newData = { ...newData, [key]: value });
    });

    return {
      ...newData,
      id: doc.id,
    } as T;
  }

  protected objectToDoc(
    obj: Optional<T, "id" | "createdAt" | "updatedAt">,
  ): DocumentValueType<T> {
    const data = {
      ...obj,
      createdAt: obj.createdAt
        ? Timestamp.fromDate(obj.createdAt)
        : Timestamp.fromDate(new Date()),
      updatedAt: obj.updatedAt
        ? Timestamp.fromDate(obj.updatedAt)
        : Timestamp.fromDate(new Date()),
    } as DocumentValueType<T>;

    return data;
  }
}
