export const DisplayPlan = {
  FREE: "フリープラン",
  STARTER: "スタータープラン",
  LIGHT: "ライトプラン",
  PRO: "プロプラン",
  ENTERPRISE: "エンタープライズプラン",
} as const;

export type PlanInfoType = {
  maxDataCapacityGB: number;
  maxAiQueries: number;
  maxLearningDatabases: number;
  maxEvaluateDatabases: number;
  switchPrompt: boolean;
  ipRestriction: boolean;
  loginDetection: boolean;
};

export const PLAN_INFO: { [key in keyof typeof DisplayPlan]: PlanInfoType } = {
  FREE: {
    maxDataCapacityGB: 1,
    maxAiQueries: 50,
    maxLearningDatabases: 0,
    maxEvaluateDatabases: 0,
    switchPrompt: false,
    ipRestriction: false,
    loginDetection: false,
  },
  STARTER: {
    maxDataCapacityGB: 1,
    maxAiQueries: 250,
    maxLearningDatabases: 3,
    maxEvaluateDatabases: 3,
    switchPrompt: true,
    ipRestriction: false,
    loginDetection: false,
  },
  LIGHT: {
    maxDataCapacityGB: 100,
    maxAiQueries: 2500,
    maxLearningDatabases: 10,
    maxEvaluateDatabases: 10,
    switchPrompt: true,
    ipRestriction: false,
    loginDetection: false,
  },
  PRO: {
    maxDataCapacityGB: 10000,
    maxAiQueries: 10000,
    maxLearningDatabases: 50,
    maxEvaluateDatabases: 50,
    switchPrompt: true,
    ipRestriction: true,
    loginDetection: true,
  },
  ENTERPRISE: {
    // 無制限
    maxDataCapacityGB: 10000,
    maxAiQueries: 10000,
    maxLearningDatabases: 50,
    maxEvaluateDatabases: 50,
    switchPrompt: true,
    ipRestriction: true,
    loginDetection: true,
  },
};
