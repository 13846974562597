import { format, startOfMonth } from "date-fns";
import ja from "date-fns/locale/ja";

export const formatDateTime = (
  date: Date | string,
  dateFormat: string,
): string => {
  return format(new Date(date), dateFormat, { locale: ja });
};

export const currentFormatTimestamp = (): string => {
  return formatDateTime(new Date(), "yyyyMMddHHmmss");
};

export const formatDate = (date: Date): string => {
  return formatDateTime(date, "yyyy/MM/dd");
};

export const formatPlainDate = (date: Date): string => {
  return formatDateTime(date, "yyyyMMdd");
};

export const formatTimestamp = (date: Date): string => {
  return formatDateTime(date, "yyyy/MM/dd HH:mm");
};

export const getStartOfMonth = (date: Date): Date => {
  return startOfMonth(date);
};
