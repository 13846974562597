import { v4 } from "uuid";

import { privateStorage } from "@spesill/config/firebase";
import { deleteObject, ref } from "@spesill/libs/firebase";
import { LearningDocumentRepository } from "@spesill/repository/db/learningDocument.repository";

import { Entity } from "./__common__/entity";

export class LearningDocument extends Entity {
  id: string;
  tenantId: string;
  learningDatabaseId: string;
  systemName: string;
  path: string;
  createUserId: string;
  lastUpdateUserId: string;
  deletedAt?: Date;
  learnedAt?: Date;

  constructor(arg: ExcludeMethods<LearningDocument>) {
    super(arg);

    this.id = arg.id;
    this.tenantId = arg.tenantId;
    this.learningDatabaseId = arg.learningDatabaseId;
    this.systemName = arg.systemName;
    this.path = arg.path;
    this.createUserId = arg.createUserId;
    this.lastUpdateUserId = arg.lastUpdateUserId;
    this.deletedAt = arg.deletedAt;
    this.learnedAt = arg.learnedAt;
  }

  static create(
    args: Omit<ConstructorParameters<typeof LearningDocument>[0], "id">,
  ) {
    return new LearningDocument({
      id: v4(),
      deletedAt: undefined,
      learnedAt: undefined,
      ...args,
    });
  }

  static async deleteWithStorage({
    learningDocument,
  }: {
    learningDocument: LearningDocument;
  }) {
    const learningDocumentRepository = new LearningDocumentRepository();

    const storageRef = ref(privateStorage, learningDocument.path);
    await deleteObject(storageRef);

    learningDocumentRepository.deleteById(learningDocument.id);
  }

  filename() {
    const matched = this.path.match(/[^/]*$/);
    if (!matched) {
      throw new Error("ファイル名を取得できませんでした");
    }
    return matched[0];
  }

  extension() {
    const matched = this.path.match(/\.[^.]*$/);
    if (!matched) {
      throw new Error("ファイル名を取得できませんでした");
    }
    return matched[0];
  }

  isExcel() {
    return this.extension() === ".xlsx";
  }

  isPdf() {
    return this.extension() === ".pdf";
  }

  isImage() {
    const imageExtensions = [".png", ".jpg", ".jpeg", ".gif", ".webp"];
    return imageExtensions.includes(this.extension());
  }
}
