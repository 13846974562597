export abstract class Entity {
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: ExcludeMethods<Entity>) {
    this.id = init.id;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }

  json() {
    return JSON.parse(JSON.stringify(this));
  }
}
