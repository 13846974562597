import { v4 } from "uuid";

import {
  doc,
  collection,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
  updateDoc,
  deleteDoc,
  QueryConstraint,
  OrderByDirection,
  orderBy,
} from "@spesill/libs/firebase";
import { Document } from "@spesill/models";
import { DocumentPermissionType } from "@spesill/models/document";

import { DBRepositoryBase } from "./__common__/DBRepositoryBase";

export class DocumentRepository extends DBRepositoryBase<
  ExcludeMethods<Document>
> {
  private PATH = "documents";

  add = async (
    document: Omit<ExcludeMethods<Document>, "id" | "createdAt" | "updatedAt">,
  ) => {
    const now = new Date();
    const docId = v4();
    const newPage = {
      ...document,
      createdAt: now,
      updatedAt: now,
      id: undefined,
      deletedAt: undefined,
    }; // idをobjectに詰めるとdocIdとは別にidというフィールドができてしまうためundefinedに

    await setDoc(doc(this.db, this.PATH, docId), this.objectToDoc(newPage));

    return new Document({ ...newPage, id: docId });
  };

  updateById = async (
    id: string,
    document: Omit<ExcludeMethods<Document>, "id" | "createdAt" | "updatedAt">,
  ) => {
    const ref = doc(this.db, this.PATH, id);
    const documentData = await getDoc(ref);

    if (!documentData.exists()) {
      throw new Error("ドキュメントが見つかりませんでした");
    } else {
      await updateDoc(ref, {
        ...this.objectToDoc(document),
        updatedAt: new Date(),
      });
      return { id };
    }
  };

  findById = async (id: string) => {
    const ref = doc(this.db, this.PATH, id);
    const document = await getDoc(ref);

    if (!document.exists()) {
      throw new Error("ドキュメントが見つかりませんでした");
    } else {
      return new Document(this.docToObject(document));
    }
  };

  findByTenantIdAndId = async (tenantId: string, id: string) => {
    const q = query(
      collection(this.db, this.PATH),
      where("tenantId", "==", tenantId),
      where("__name__", "==", id),
    );
    const docs = await getDocs(q).then((res) => res.docs);

    if (!docs[0]) {
      throw new Error("ドキュメントが見つかりませんでした");
    }
    return new Document(this.docToObject(docs[0]));
  };

  findByTenantId = async (
    tenantId: string,
    filter?: {
      createUserId?: string;
      learningDatabaseId?: string;
    },
    sort?: {
      field: keyof Document;
      order: OrderByDirection;
    },
  ) => {
    const queryConstraints: QueryConstraint[] = [
      where("tenantId", "==", tenantId),
    ];
    if (filter?.createUserId) {
      queryConstraints.push(where("createUserId", "==", filter.createUserId));
    }
    if (filter?.learningDatabaseId) {
      queryConstraints.push(
        where("learningDatabaseId", "==", filter.learningDatabaseId),
      );
    }
    if (sort) {
      queryConstraints.push(orderBy(sort.field, sort.order));
    }
    const q = query(collection(this.db, this.PATH), ...queryConstraints);
    const docs = await getDocs(q).then((res) => res.docs);

    if (!docs) {
      throw new Error("ドキュメントが見つかりませんでした");
    }

    return docs.map((doc) => new Document(this.docToObject(doc)));
  };

  deleteById = async (id: string) => {
    const ref = doc(this.db, this.PATH, id);
    const document = await getDoc(ref);

    if (!document.exists() || !document.data()?.deletedAt) {
      throw new Error("ドキュメントが見つかりませんでした");
    } else {
      await deleteDoc(ref);
    }
  };

  // 論理削除
  logicalDeleteById = async (id: string) => {
    const ref = doc(this.db, this.PATH, id);
    const document = await getDoc(ref);

    if (!document.exists()) {
      throw new Error("ドキュメントが見つかりませんでした");
    } else {
      await updateDoc(ref, { deletedAt: new Date() });
    }
  };

  restoreById = async (id: string) => {
    const ref = doc(this.db, this.PATH, id);
    const document = await getDoc(ref);

    if (!document.exists() || !document.data()?.deletedAt) {
      throw new Error("ドキュメントが見つかりませんでした");
    } else {
      await updateDoc(ref, { deletedAt: null });
    }
  };

  updatePermissionLevel = async (
    id: string,
    permissionLevel: DocumentPermissionType,
  ) => {
    const ref = doc(this.db, this.PATH, id);
    const document = await getDoc(ref);

    if (!document.exists()) {
      throw new Error("ドキュメントが見つかりませんでした");
    } else {
      await updateDoc(ref, {
        permissionLevel: permissionLevel,
        updatedAt: new Date(),
      });
    }
  };
}
