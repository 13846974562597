import { v4 } from "uuid";

import { TenantAiUsageRepository } from "@spesill/repository/db/tenantAiUsage.repository";

import { Entity } from "./__common__/entity";
import { DisplayPlan, PLAN_INFO } from "./constants/plan";

export class Tenant extends Entity {
  id: string;
  name: string;
  plan: keyof typeof DisplayPlan;
  additionalAiUsage?: number;
  additionalDatabaseCount?: number;
  additionalDataCapacityGB?: number;
  isEnable2fa?: boolean = false;
  isEnableIpRestriction?: boolean = false;

  constructor(arg: ExcludeMethods<Omit<Tenant, "displayPlan">>) {
    super(arg);

    this.id = arg.id;
    this.name = arg.name;
    this.plan = arg.plan;
    this.additionalAiUsage = arg.additionalAiUsage;
    this.additionalDatabaseCount = arg.additionalDatabaseCount;
    this.additionalDataCapacityGB = arg.additionalDataCapacityGB;
    this.isEnable2fa = arg.isEnable2fa;
    this.isEnableIpRestriction = arg.isEnableIpRestriction;
  }

  static create(args: Omit<ConstructorParameters<typeof Tenant>[0], "id">) {
    return new Tenant({
      id: v4(),
      ...args,
    });
  }

  get displayPlan() {
    return DisplayPlan[this.plan];
  }

  async canAddUser(): Promise<boolean> {
    return this.plan !== "FREE";
  }

  async canCallAi(): Promise<boolean> {
    const tenantAiUsageRepository = new TenantAiUsageRepository();
    let currentMonthUsage = 0;

    try {
      const aiUsage = await tenantAiUsageRepository.findOrAddByThisMonth(
        this.id,
      );
      currentMonthUsage = aiUsage.totalCount();
    } catch (error) {
      // 当月のデータがまだない場合は0とする
    }

    const currentPlan = PLAN_INFO[this.plan];
    const maxLimit = currentPlan.maxAiQueries + (this.additionalAiUsage || 0);

    return currentMonthUsage < maxLimit;
  }
}
