import {
  User,
  Document,
  UsersPostsNotificationStatus,
  LearningDatabase,
  EvaluateDatabase,
} from "@spesill/models";
import { PostsNotificationRepository } from "@spesill/repository/db/postsNotification.repository";

import { Entity } from "./__common__/entity";

export class PostsNotification extends Entity {
  id: string;
  tenantId: string;
  createUserId: string;
  createUserName: string;
  resourceTitle: string;
  resourceId: string;
  itemType: "Document" | "LearningDatabase" | "EvaluateDatabase";
  scopeTarget: "all" | "myself";
  action: "create" | "delete";

  constructor(arg: Omit<ExcludeMethods<PostsNotification>, "title">) {
    super(arg);

    this.id = arg.id;
    this.tenantId = arg.tenantId;
    this.createUserId = arg.createUserId;
    this.createUserName = arg.createUserName;
    this.resourceTitle = arg.resourceTitle;
    this.resourceId = arg.resourceId;
    this.itemType = arg.itemType;
    this.scopeTarget = arg.scopeTarget;
    this.action = arg.action;
  }

  static async create(
    args: Omit<
      ConstructorParameters<typeof PostsNotification>[0],
      "id" | "createdAt" | "updatedAt" | "title"
    >,
  ) {
    const postsNotificationRepository = new PostsNotificationRepository();
    const postsNotification = await postsNotificationRepository.add({
      ...args,
    });
    return postsNotification;
  }

  static async insertNewNotification({
    resource,
    createUser,
    action,
  }: {
    resource: Document | LearningDatabase | EvaluateDatabase;
    createUser: User;
    action: "create" | "delete";
  }) {
    const users = await createUser.sameTenantUsers();
    const toAllPostsNotification = await PostsNotification.create({
      tenantId: resource.tenantId,
      createUserId: createUser.id,
      createUserName: createUser.fullName,
      resourceTitle: resource.systemName,
      resourceId: resource.id,
      itemType: this.getItemType(resource),
      scopeTarget: "all",
      action: action,
    });
    const toMyselfPostsNotification = await PostsNotification.create({
      tenantId: resource.tenantId,
      createUserId: createUser.id,
      createUserName: createUser.fullName,
      resourceTitle: resource.systemName,
      resourceId: resource.id,
      itemType: this.getItemType(resource),
      scopeTarget: "myself",
      action: action,
    });

    UsersPostsNotificationStatus.bulkCreate({
      postsNotification: toAllPostsNotification,
      users: users,
    });
    UsersPostsNotificationStatus.create({
      tenantId: resource.tenantId,
      postsNotificationId: toMyselfPostsNotification.id,
      userId: createUser.id,
    });
  }

  private static getItemType(
    resource: Document | LearningDatabase | EvaluateDatabase,
  ): "Document" | "LearningDatabase" | "EvaluateDatabase" {
    if (resource instanceof Document) {
      return "Document";
    }
    if (resource instanceof LearningDatabase) {
      return "LearningDatabase";
    }
    if (resource instanceof EvaluateDatabase) {
      return "EvaluateDatabase";
    }
    throw new Error("Unsupported resource type");
  }
}
