import { v4 } from "uuid";

import { UserRepository } from "@spesill/repository/db/user.repository";

import { Entity } from "./__common__/entity";

export const DisplayRole = {
  ADMIN: "管理者",
  MEMBER: "メンバー",
} as const;

export class User extends Entity {
  id: string;
  /**
   * Firebase Authenticationのuid
   * undefinedのときは未認証であり、Authと紐づいていない
   */
  uid?: string;
  lastName: string;
  firstName: string;
  email: string;
  department?: string;
  jobDescription?: string;
  tenantId: string;
  role: keyof typeof DisplayRole;

  // getter/setterはユーティリティ型で判別できないので明示的にOmitする
  constructor(arg: Omit<ExcludeMethods<User>, "displayRole" | "fullName">) {
    super(arg);

    this.id = arg.id;
    this.uid = arg.uid;
    this.lastName = arg.lastName;
    this.firstName = arg.firstName;
    this.email = arg.email;
    this.department = arg.department;
    this.jobDescription = arg.jobDescription;
    this.tenantId = arg.tenantId;
    this.role = arg.role;
  }

  static create(args: Omit<ConstructorParameters<typeof User>[0], "id">) {
    return new User({
      id: v4(),
      ...args,
    });
  }

  /**
   * アカウントをまだ作成していないかどうか
   */
  isUnregistered() {
    return !this.uid;
  }

  get displayRole() {
    return DisplayRole[this.role];
  }

  get fullName() {
    return `${this.lastName} ${this.firstName}`;
  }

  async sameTenantUsers() {
    const userRepository = new UserRepository();
    const users = await userRepository.findByTenantId(this.tenantId);
    return users.filter((user) => user.id !== this.id);
  }
}
