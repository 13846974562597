import { v4 } from "uuid";

import {
  doc,
  collection,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
  setDoc,
  deleteDoc,
} from "@spesill/libs/firebase";
import { User } from "@spesill/models";

import { DBRepositoryBase } from "./__common__/DBRepositoryBase";

export class UserRepository extends DBRepositoryBase<ExcludeMethods<User>> {
  private PATH = "users";

  add = async (
    user: Optional<ExcludeMethods<User>, "id" | "createdAt" | "updatedAt">,
  ) => {
    const docId = user.id || v4();
    const newUser = {
      ...user,
      createdAt: new Date(),
      updatedAt: new Date(),
      id: undefined,
    }; // idをobjectに詰めるとdocIdとは別にidというフィールドができてしまうためundefinedに

    return await setDoc(
      doc(this.db, this.PATH, docId),
      this.objectToDoc(newUser),
    );
  };

  findById = async (id: string) => {
    const ref = doc(this.db, this.PATH, id);
    const document = await getDoc(ref);

    if (!document.exists()) {
      throw new Error("ユーザーが見つかりませんでした");
    } else {
      return new User(this.docToObject(document));
    }
  };

  update = async (id: string, updateObject: Partial<User>) => {
    const ref = doc(this.db, this.PATH, id);
    return await updateDoc(ref, updateObject);
  };

  findByUid = async (uid: string) => {
    const q = query(collection(this.db, this.PATH), where("uid", "==", uid));
    const doc = await getDocs(q).then((res) => res.docs[0]);

    if (!doc) {
      throw new Error("ユーザーが見つかりませんでした");
    }

    return new User(this.docToObject(doc));
  };

  findByTenantId = async (tenantId: string) => {
    const q = query(
      collection(this.db, this.PATH),
      where("tenantId", "==", tenantId),
    );
    const docs = await getDocs(q).then((res) => res.docs);

    if (!docs) {
      throw new Error("ユーザーが見つかりませんでした");
    }

    return docs.map((doc) => new User(this.docToObject(doc)));
  };

  findByEmail = async (email: string) => {
    const q = query(
      collection(this.db, this.PATH),
      where("email", "==", email),
    );
    const docs = await getDocs(q).then((res) => res.docs);

    if (!docs) {
      throw new Error("ユーザーが見つかりませんでした");
    }

    return docs.map((doc) => new User(this.docToObject(doc)));
  };

  deleteById = async (id: string) => {
    const ref = doc(this.db, this.PATH, id);
    const document = await getDoc(ref);

    if (!document.exists()) {
      throw new Error("ユーザーが見つかりませんでした");
    } else {
      await deleteDoc(ref);
    }
  };
}
