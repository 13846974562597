import { v4 } from "uuid";

import { doc, getDoc, setDoc, updateDoc } from "@spesill/libs/firebase";
import { Tenant } from "@spesill/models";

import { DBRepositoryBase } from "./__common__/DBRepositoryBase";

export class TenantRepository extends DBRepositoryBase<ExcludeMethods<Tenant>> {
  private PATH = "tenants";

  findById = async (id: string) => {
    const ref = doc(this.db, this.PATH, id);
    const document = await getDoc(ref);

    if (!document.exists()) {
      throw new Error("ユーザーが見つかりませんでした");
    } else {
      return new Tenant(this.docToObject(document));
    }
  };

  add = async (
    user: Optional<ExcludeMethods<Tenant>, "id" | "createdAt" | "updatedAt">,
  ) => {
    const docId = user.id || v4();
    const newTenant = {
      ...user,
      createdAt: new Date(),
      updatedAt: new Date(),
      id: undefined,
    }; // idをobjectに詰めるとdocIdとは別にidというフィールドができてしまうためundefinedに

    await setDoc(doc(this.db, this.PATH, docId), this.objectToDoc(newTenant));

    return new Tenant({ ...newTenant, id: docId });
  };

  update = async (id: string, updateObject: Partial<Tenant>) => {
    const ref = doc(this.db, this.PATH, id);
    return await updateDoc(ref, {
      ...updateObject,
      updatedAt: new Date(),
    });
  };
}
