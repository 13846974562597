import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
} from "@spesill/libs/firebase";
import { PermittedUser } from "@spesill/models";

import { DBRepositoryBase } from "./__common__/DBRepositoryBase";

export class PermittedUserRepository extends DBRepositoryBase<
  ExcludeMethods<PermittedUser>
> {
  private readonly DOCUMENTS_PATH = "documents";

  add = async (
    documentId: string,
    userId: string,
    permittedUser: Omit<
      ExcludeMethods<PermittedUser>,
      "id" | "createdAt" | "updatedAt"
    >,
  ) => {
    const now = new Date();

    const newPermission = {
      ...permittedUser,
      createdAt: now,
      updatedAt: now,
      id: undefined,
    };

    await setDoc(
      doc(this.db, this.DOCUMENTS_PATH, documentId, "permittedUsers", userId),
      this.objectToDoc(newPermission),
    );

    return new PermittedUser({ ...newPermission, id: userId });
  };

  findByDocumentId = async (documentId: string) => {
    const q = query(
      collection(this.db, this.DOCUMENTS_PATH, documentId, "permittedUsers"),
    );

    const permittedUsers = await getDocs(q).then((res) => res.docs);

    return permittedUsers.map(
      (permittedUser) => new PermittedUser(this.docToObject(permittedUser)),
    );
  };

  deleteByDocumentId = async (documentId: string) => {
    const q = query(
      collection(this.db, this.DOCUMENTS_PATH, documentId, "permittedUsers"),
    );

    const permittedUsers = await getDocs(q);

    if (permittedUsers.empty) {
      throw new Error("ドキュメントが見つかりませんでした");
    }

    for (const documentPermission of permittedUsers.docs) {
      await deleteDoc(documentPermission.ref);
    }
  };

  deleteById = async (documentId: string, userId: string) => {
    const ref = doc(
      this.db,
      this.DOCUMENTS_PATH,
      documentId,
      "permittedUsers",
      userId,
    );
    await deleteDoc(ref);
  };
}
