import { User, PostsNotification } from "@spesill/models";
import { UsersPostsNotificationStatusRepository } from "@spesill/repository/db/usersPostsNotificationStatus.repository";

import { Entity } from "./__common__/entity";

const usersPostsNotificationStatusRepository =
  new UsersPostsNotificationStatusRepository();

export class UsersPostsNotificationStatus extends Entity {
  id: string;
  tenantId: string;
  userId: string;
  postsNotificationId: string;
  readAt: Date | null;

  constructor(arg: ExcludeMethods<UsersPostsNotificationStatus>) {
    super(arg);

    this.id = arg.id;
    this.tenantId = arg.tenantId;
    this.userId = arg.userId;
    this.postsNotificationId = arg.postsNotificationId;
    this.readAt = arg.readAt;
  }

  static async create(
    args: Omit<
      ConstructorParameters<typeof UsersPostsNotificationStatus>[0],
      "id" | "readAt" | "createdAt" | "updatedAt"
    >,
  ) {
    return await usersPostsNotificationStatusRepository.add({
      ...args,
    });
  }

  static bulkCreate = async ({
    postsNotification,
    users,
  }: {
    postsNotification: Omit<
      ExcludeMethods<PostsNotification>,
      "createdAt" | "updatedAt"
    >;
    users: User[];
  }) => {
    const usersPostsNotificationStatus = users.map((user) => {
      return {
        tenantId: postsNotification.tenantId,
        userId: user.id,
        postsNotificationId: postsNotification.id,
        readAt: undefined,
      };
    });
    await Promise.all(
      usersPostsNotificationStatus.map((userPostsNotificationStatus) => {
        return this.create(userPostsNotificationStatus);
      }),
    );
  };

  static bulkRead = async ({
    postsNotificationIds,
    userId,
  }: {
    postsNotificationIds: string[];
    userId: string;
  }) => {
    const readTargetStatuses =
      await usersPostsNotificationStatusRepository.findByNotificationAndUserId({
        postsNotificationIds,
        userId,
      });
    await Promise.all(
      readTargetStatuses.map((status) => {
        const now = new Date();
        return usersPostsNotificationStatusRepository.update({
          ...status,
          readAt: now,
        });
      }),
    );

    return readTargetStatuses;
  };
}
