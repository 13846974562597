import { Entity } from "./__common__/entity";

export class DocumentViewer extends Entity {
  id: string;
  tenantId: string;
  viewedAt?: Date;

  constructor(arg: ExcludeMethods<DocumentViewer>) {
    super(arg);

    this.id = arg.id;
    this.tenantId = arg.tenantId;
    this.viewedAt = arg.viewedAt;
  }

  static create(
    args: Omit<ConstructorParameters<typeof DocumentViewer>[0], "id"> & {
      userId: string;
    },
  ) {
    return new DocumentViewer({
      id: args.userId,
      ...args,
    });
  }
}
