import { v4 } from "uuid";

import { Entity } from "./__common__/entity";

export class TenantWhiteIpList extends Entity {
  id: string;
  ip: string;
  tenantId: string;
  createUserId: string;

  constructor(arg: ExcludeMethods<TenantWhiteIpList>) {
    super(arg);

    this.id = arg.id;
    this.ip = arg.ip;
    this.tenantId = arg.tenantId;
    this.createUserId = arg.createUserId;
  }

  static async create(
    args: Omit<ConstructorParameters<typeof TenantWhiteIpList>[0], "id">,
  ) {
    return new TenantWhiteIpList({
      id: v4(),
      ...args,
    });
  }
}
