import { v4 } from "uuid";

import { privateStorage } from "@spesill/config/firebase";
import { deleteObject, listAll, ref } from "@spesill/libs/firebase";
import { EvaluateDocument, PostsNotification, User } from "@spesill/models";
import { EvaluateDatabaseRepository } from "@spesill/repository/db/evaluateDatabase.repository";
import { EvaluateDocumentRepository } from "@spesill/repository/db/evaluateDocument.repository";

import { Entity } from "./__common__/entity";

export class EvaluateDatabase extends Entity {
  id: string;
  tenantId: string;
  systemName: string;
  createUserId: string;
  lastUpdateUserId: string;
  deletedAt?: Date;

  constructor(arg: ExcludeMethods<EvaluateDatabase>) {
    super(arg);

    this.id = arg.id;
    this.tenantId = arg.tenantId;
    this.systemName = arg.systemName;
    this.createUserId = arg.createUserId;
    this.lastUpdateUserId = arg.lastUpdateUserId;
    this.deletedAt = arg.deletedAt;
  }

  static create(
    args: Omit<ConstructorParameters<typeof EvaluateDatabase>[0], "id">,
  ) {
    return new EvaluateDatabase({
      id: v4(),
      deletedAt: undefined,
      ...args,
    });
  }

  static async deleteWithEvaluateDocuments({
    evaluateDatabase,
    user,
  }: {
    evaluateDatabase: EvaluateDatabase;
    user: User;
  }) {
    const evaluateDatabaseRepository = new EvaluateDatabaseRepository();
    const evaluateDocuments = await evaluateDatabase.evaluateDocuments();

    const path = `tenants/${evaluateDatabase.tenantId}/vector_indices/evaluates/${evaluateDatabase.id}`;
    try {
      listAll(ref(privateStorage, path))
        .then((res) => res.items)
        .then((items) => {
          items.forEach((item) => {
            deleteObject(item);
          });
        });
    } catch (error) {
      // NOTE: vector_indicesが存在しない場合はエラーになるが、無視してかまわない
      console.error(error);
    }

    await Promise.all(
      evaluateDocuments.map(async (evaluateDocument) => {
        await EvaluateDocument.deleteWithStorage({
          evaluateDocument,
        });
      }),
    );
    evaluateDatabaseRepository.deleteById(evaluateDatabase.id);
    PostsNotification.insertNewNotification({
      resource: evaluateDatabase,
      createUser: user,
      action: "delete",
    });
  }

  async evaluateDocuments() {
    const evaluateDocumentRepository = new EvaluateDocumentRepository();
    const evaluateDocuments =
      await evaluateDocumentRepository.findByTenantIdAndEvaluateDatabaseId(
        this.tenantId,
        this.id,
      );
    return evaluateDocuments;
  }
}
