import { privateStorage } from "@spesill/config/firebase";
import { deleteObject, ref } from "@spesill/libs/firebase";
import { PunchImageRepository } from "@spesill/repository/db/punchImage.repository";

import { Entity } from "./__common__/entity";

type PunchItemType = "NONE" | "PATHWAY" | "PATHWAY_CORNER" | "ROBOT" | "TARGET";

export class PunchImage extends Entity {
  id: string;
  tenantId: string;
  name: string;
  description?: string;
  height: number;
  width: number;
  path: string;
  deletedAt?: Date | null;
  /**
   * single: アイテムを単体で使う
   * multi: アイテムを並べて使う
   * */
  arrangementType: "single" | "multi";
  /**
   * itemTypesにAI側で必要な情報を保存する
   * arrangementTypeがsingleの場合[]で保存
   * arrangementTypeがmultiの場合[PATHWAY]で保存
   * */
  itemTypes: PunchItemType[];

  constructor(arg: ExcludeMethods<PunchImage>) {
    super(arg);

    this.id = arg.id;
    this.tenantId = arg.tenantId;
    this.name = arg.name;
    this.path = arg.path;
    this.height = arg.height;
    this.width = arg.width;
    this.deletedAt = arg.deletedAt;
    this.description = arg.description;
    this.arrangementType = arg.arrangementType;
    this.itemTypes = arg.itemTypes;
  }

  static create(
    args: Omit<
      ConstructorParameters<typeof PunchImage>[0],
      "id" | "createdAt" | "updatedAt"
    >,
  ) {
    const newPunch = {
      deletedAt: null,
      ...args,
    };
    const punchImageRepository = new PunchImageRepository();
    return punchImageRepository.add({ ...newPunch });
  }

  static async update(
    args: Omit<
      ConstructorParameters<typeof PunchImage>[0],
      "createdAt" | "updatedAt"
    >,
  ) {
    const punchImageRepository = new PunchImageRepository();
    const newPunch = {
      ...args,
    };
    punchImageRepository.updateById(args.id, { ...newPunch });
  }

  static async deleteWithStorage({ PunchImage }: { PunchImage: PunchImage }) {
    const punchImageRepository = new PunchImageRepository();

    const storageRef = ref(privateStorage, PunchImage.path);
    await deleteObject(storageRef);

    punchImageRepository.deleteById(PunchImage.id);
  }

  filename() {
    const matched = this.path.match(/[^/]*$/);
    if (!matched) {
      throw new Error("ファイル名を取得できませんでした");
    }
    return matched[0];
  }
}
