import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";

import { useRouter } from "next/router";

import { auth } from "@spesill/config/firebase";
import { User } from "@spesill/models";
import { DisplayPlan } from "@spesill/models/constants/plan";
import { TenantRepository } from "@spesill/repository/db/tenant.repository";
import { UserRepository } from "@spesill/repository/db/user.repository";

// 未認証でもアクセスできるpath
const ALLOW_UNAUTHENTICATED_USER_PATHS = [
  "/sign_in",
  "/sign_in/2fa",
  "/sample",
  "/users/new/[id]",
  "/shared/verify",
];

// フリープランのユーザーがアクセスできるpath
const FREE_PLAN_ACCESSIBLE_PATHS = [
  "/",
  "/documents/[id]",
  "/settings/plan",
  "/settings/users",
  "/settings/profile",
  "/settings/document",
];

const userRepository = new UserRepository();
const tenantRepository = new TenantRepository();

export const CurrentUserContext = createContext<{
  currentUser: User | undefined;
  refetchCurrentUser: () => void;
  currentPlan?: keyof typeof DisplayPlan;
}>({
  currentUser: undefined,
  refetchCurrentUser: () => void 0,
  currentPlan: undefined,
});

type Props = {
  children: ReactNode;
};

export const CurrentUserProvider = ({ children }: Props) => {
  const router = useRouter();
  const [currentUser, setCurrentUser] = useState<User>();
  const [currentPlan, setCurrentPlan] = useState<keyof typeof DisplayPlan>();

  const fetchCurrentUser = useCallback(() => {
    // unsubscribeが呼び出されるとリスナーを削除する
    const unsubscribe = auth.onIdTokenChanged(async (authUser) => {
      // authUserが存在する場合はcurrentUserを更新する
      if (authUser) {
        await userRepository
          .findByUid(authUser.uid)
          .then(async (user) => {
            setCurrentUser(user);
            // フリープランのユーザーは特定のページ以外にアクセスできない
            const tenant = await tenantRepository.findById(user.tenantId);
            setCurrentPlan(tenant?.plan);
            if (
              tenant?.plan === "FREE" &&
              !FREE_PLAN_ACCESSIBLE_PATHS.includes(router.pathname)
            ) {
              router.push("/");
              return;
            }
            // サインインページにいる場合はホームにリダイレクトする
            if (router.pathname === "/sign_in") {
              router.push("/");
            }
          })
          .catch(() => void 0); // 見つからなかった場合、currentUserがundefinedのままになるだけで何もしない
      } else {
        // 未認証を許可するページでは何もしない
        if (ALLOW_UNAUTHENTICATED_USER_PATHS.includes(router.pathname)) {
          return;
        }
        // authUserが存在しない場合は未認証なのでサインインページにリダイレクトする
        router.push("/sign_in");
      }
    });

    return unsubscribe;
  }, [router]);

  useEffect(() => {
    const unsubscribe = fetchCurrentUser();

    return () => unsubscribe();
  }, [fetchCurrentUser]);

  return (
    <CurrentUserContext.Provider
      value={{ currentUser, refetchCurrentUser: fetchCurrentUser, currentPlan }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};
