import { privateStorage } from "@spesill/config/firebase";
import { deleteObject, ref } from "@spesill/libs/firebase";
import { PostsNotification, User } from "@spesill/models";
import { DocumentRepository } from "@spesill/repository/db/document.repository";
import { PermittedUserRepository } from "@spesill/repository/db/PermittedUser.repository";

import { Entity } from "./__common__/entity";

export type DocumentPermissionType = "all" | "specific";
export type DocumentUserPermission = "viewer" | "editor" | "none";
export type DocumentType = "word" | "excel";
export type DocumentKind = keyof typeof DisplayDocumentKind;
export type DocumentLanguageType = keyof typeof DisplayDocumentLanguages;

export const DisplayDocumentKind = {
  meetingMinutes: "議事録",
  productDescription: "製品説明",
  presentationSlidesAndNotes: "プレゼン資料",
  report: "報告書",
  proposal: "提案書",
  manual: "手順書",
  specification: "仕様書",
  agreement: "契約書",
  researchArticle: "論文",
  others: "その他",
} as const;

export const DisplayDocumentLanguages = {
  DEFAULT: "デフォルト(日本語)",
  ENGLISH: "英語",
  JAPANESE: "日本語",
  CHINESE: "中国語",
  KOREAN: "韓国語",
  FRENCH: "フランス語",
  SPANISH: "スペイン語",
  PORTUGUESE: "ポルトガル語",
  RUSSIAN: "ロシア語",
  ARABIC: "アラビア語",
  GERMAN: "ドイツ語",
  ITALIAN: "イタリア語",
  HINDI: "ヒンディー語",
  INDONESIAN: "インドネシア語",
  THAI: "タイ語",
  VIETNAMESE: "ベトナム語",
  MALAY: "マレー語",
  TURKISH: "トルコ語",
  PERSIAN: "ペルシャ語",
  HEBREW: "ヘブライ語",
  SWEDISH: "スウェーデン語",
  DUTCH: "オランダ語",
  NORWEGIAN: "ノルウェー語",
  FINNISH: "フィンランド語",
  DANISH: "デンマーク語",
  POLISH: "ポーランド語",
  CZECH: "チェコ語",
  HUNGARIAN: "ハンガリー語",
  ROMANIAN: "ルーマニア語",
  BULGARIAN: "ブルガリア語",
  GREEK: "ギリシャ語",
  SERBIAN: "セルビア語",
  UKRAINIAN: "ウクライナ語",
  SLOVAK: "スロバキア語",
  SLOVENIAN: "スロベニア語",
  LITHUANIAN: "リトアニア語",
  LATVIAN: "ラトビア語",
  ESTONIAN: "エストニア語",
  ALBANIAN: "アルバニア語",
  CROATIAN: "クロアチア語",
  BOSNIAN: "ボスニア語",
  MACEDONIAN: "マケドニア語",
  ICELANDIC: "アイスランド語",
  WELSH: "ウェールズ語",
  IRISH: "アイルランド語",
  SCOTTISH: "スコットランド語",
  BASQUE: "バスク語",
  CATALAN: "カタロニア語",
  GALICIAN: "ガリシア語",
  TAGALOG: "タガログ語",
  ESPERANTO: "エスペラント語",
  LATIN: "ラテン語",
  HAWAIIAN: "ハワイ語",
  MAORI: "マオリ語",
} as const;

export class Document extends Entity {
  id: string;
  tenantId: string;
  systemName: string;
  path: string;
  html?: string; // documentTypeがwordの場合htmlとストレージ(path)を保存。excelの場合はストレージ(path)のみ保存。
  documentType?: DocumentType;
  learningDatabaseId?: string | null;
  createUserId: string;
  lastUpdateUserId: string;
  permissionLevel?: DocumentPermissionType;
  documentCustomPrompt?: string;
  kind?: DocumentKind;
  freeInputKind?: string;
  tenantName?: string;
  tenantBusinessDetails?: string;
  userDepartment?: string;
  userJobDescription?: string;
  deletedAt?: Date | null;
  language?: DocumentLanguageType;
  referenceDocumentIds?: string[];

  constructor(arg: ExcludeMethods<Document>) {
    super(arg);

    this.id = arg.id;
    this.tenantId = arg.tenantId;
    this.systemName = arg.systemName;
    this.path = arg.path;
    this.html = arg.html;
    this.learningDatabaseId = arg.learningDatabaseId;
    this.createUserId = arg.createUserId;
    this.lastUpdateUserId = arg.lastUpdateUserId;
    this.deletedAt = arg.deletedAt;
    this.permissionLevel = arg.permissionLevel;
    this.documentCustomPrompt = arg.documentCustomPrompt;
    this.kind = arg.kind;
    this.freeInputKind = arg.freeInputKind;
    this.tenantName = arg.tenantName;
    this.tenantBusinessDetails = arg.tenantBusinessDetails;
    this.userDepartment = arg.userDepartment;
    this.userJobDescription = arg.userJobDescription;
    this.language = arg.language;
    this.documentType = arg.documentType;
    this.referenceDocumentIds = arg.referenceDocumentIds;
  }

  static async create(
    args: Omit<
      ConstructorParameters<typeof Document>[0],
      "id" | "createdAt" | "updatedAt"
    >,
  ) {
    const documentRepository = new DocumentRepository();
    const newDocument = {
      deletedAt: undefined,
      permissionLevel: undefined,
      ...args,
    };
    const document = await documentRepository.add(newDocument);
    return document;
  }

  static async deleteWithStorage({
    document,
    user,
  }: {
    document: Document;
    user: User;
  }) {
    const documentRepository = new DocumentRepository();

    const storageRef = ref(privateStorage, document.path);
    await deleteObject(storageRef);
    documentRepository.deleteById(document.id);

    PostsNotification.insertNewNotification({
      resource: document,
      createUser: user,
      action: "delete",
    });
  }

  filename() {
    const matched = this.path.match(/[^/]*$/);
    if (!matched) {
      throw new Error("ファイル名を取得できませんでした");
    }
    return matched[0];
  }

  extension() {
    const matched = this.path.match(/\.[^.]*$/);
    if (!matched) {
      throw new Error("ファイル名を取得できませんでした");
    }
    const extension = matched[0];

    switch (extension) {
      case ".pdf":
        return "PDF";
      case ".doc":
      case ".docx":
        return "Word";
      case ".xlsx":
        return "Excel";
      default:
        return "OTHER";
    }
  }

  async userPermission(user: User): Promise<DocumentUserPermission> {
    // 作成者は常に編集権限を持つ
    if (this.createUserId === user.id) {
      return "editor";
    }

    if (this.permissionLevel === "all") {
      return "editor";
    }

    if (this.permissionLevel === "specific") {
      const permittedUserRepository = new PermittedUserRepository();
      const permittedUsers = await permittedUserRepository.findByDocumentId(
        this.id,
      );

      const isAccessPermitted = permittedUsers.some(
        (permittedUser) => permittedUser.id === user.id,
      );

      // ユーザーが権限のリストにない場合
      if (!isAccessPermitted) {
        return "none";
      }

      const userPermission = permittedUsers.find(
        (permittedUser) => permittedUser.id === user.id,
      );
      const permission = userPermission?.permission || "viewer";
      return permission;
    }

    return "editor";
  }
}
