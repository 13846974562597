import { formatPlainDate, getStartOfMonth } from "@spesill/libs/dateFns";
import {
  collection,
  doc,
  getDocs,
  query,
  runTransaction,
  setDoc,
  where,
} from "@spesill/libs/firebase";
import { TenantAiUsage } from "@spesill/models";
import { aiCounterFieldType } from "@spesill/models/tenantAiUsage";

import { DBRepositoryBase } from "./__common__/DBRepositoryBase";

export class TenantAiUsageRepository extends DBRepositoryBase<
  ExcludeMethods<TenantAiUsage>
> {
  private readonly TENANTS_PATH = "tenants";
  private readonly SUB_COLLECTION_PATH = "aiUsages";

  private getCollectionRef(tenantId: string) {
    return collection(
      this.db,
      this.TENANTS_PATH,
      tenantId,
      this.SUB_COLLECTION_PATH,
    );
  }

  private getDocumentRef(tenantId: string, aiUsageId: string) {
    return doc(
      this.db,
      this.TENANTS_PATH,
      tenantId,
      this.SUB_COLLECTION_PATH,
      aiUsageId,
    );
  }

  add = async (
    tenantId: string,
    tenantAiUsage: Omit<
      ExcludeMethods<TenantAiUsage>,
      "id" | "createdAt" | "updatedAt"
    >,
  ) => {
    const now = new Date();
    const docId = `${tenantId}_${formatPlainDate(
      tenantAiUsage.targetMonthDate,
    )}`;

    const newUsage = {
      ...tenantAiUsage,
      createdAt: now,
      updatedAt: now,
      tenantId: tenantId,
      id: undefined,
    };

    await setDoc(
      this.getDocumentRef(tenantId, docId),
      this.objectToDoc(newUsage),
    );

    return new TenantAiUsage({
      ...newUsage,
      id: docId,
    });
  };

  findOrAddByThisMonth = async (tenantId: string) => {
    const now = new Date();
    const startOfMonth = getStartOfMonth(now);

    const documentRef = this.getCollectionRef(tenantId);
    const q = query(documentRef, where("targetMonthDate", "==", startOfMonth));

    const docs = await getDocs(q).then((res) => res.docs);
    if (!docs[0]) {
      const defaultTenantAiUsage = {
        targetMonthDate: startOfMonth,
        generateSpec: 0,
        generateSpecHeadings: 0,
        generateVectorIndices: 0,
        evaluateSpec: 0,
        aiChat: 0,
        tenantId,
        createdAt: now,
        updatedAt: now,
      };
      return this.add(tenantId, defaultTenantAiUsage);
    }
    return new TenantAiUsage(this.docToObject(docs[0]));
  };

  incrementCountField = async (
    tenantId: string,
    field: aiCounterFieldType,
    count: number,
  ) => {
    const now = new Date();
    const startOfMonth = getStartOfMonth(now);

    const documentRef = this.getCollectionRef(tenantId);
    const q = query(documentRef, where("targetMonthDate", "==", startOfMonth));
    const doc = await getDocs(q).then((res) => res.docs[0]);

    if (!doc) {
      throw new Error("対象のドキュメントが見つかりませんでした");
    }

    await runTransaction(this.db, async (transaction) => {
      const docSnap = await transaction.get(doc.ref);
      const data = docSnap.data();
      const currentFieldCount = data?.[field] || 0; // フィールドが存在しない場合は0をデフォルトとする

      if (typeof currentFieldCount !== "number") {
        throw new Error("カウントフィールドが不正です");
      }

      // カウントを増やし、updatedAtを更新します。
      transaction.update(doc.ref, {
        [field]: currentFieldCount + count,
        updatedAt: new Date(),
      });
    });
  };
}
