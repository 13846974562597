/* eslint-disable no-restricted-imports */
export { initializeApp, FirebaseError } from "firebase/app";
export type { FirebaseOptions } from "firebase/app";
export { getAnalytics } from "firebase/analytics";

export {
  initializeFirestore,
  collection,
  query,
  getDocs,
  doc,
  getDoc,
  setDoc,
  QueryDocumentSnapshot,
  Timestamp,
  getFirestore,
  deleteDoc,
  updateDoc,
  FirestoreError,
  onSnapshot,
  orderBy,
  where,
  limit,
  QueryConstraint,
  runTransaction,
} from "firebase/firestore";

export type {
  DocumentData,
  FirestoreErrorCode,
  OrderByDirection,
  Unsubscribe,
} from "firebase/firestore";

export {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signOut,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  updateEmail,
  getIdTokenResult,
} from "firebase/auth";
export type { Auth, User as AuthUser } from "firebase/auth";

export {
  getStorage,
  getMetadata,
  getBlob,
  getDownloadURL,
  ref,
  uploadBytes,
  deleteObject,
  listAll,
} from "firebase/storage";

export type { StorageReference } from "firebase/storage";
