import { v4 } from "uuid";

import { Entity } from "./__common__/entity";

export class MasterPrompt extends Entity {
  id: string;
  prompt: string;

  constructor(arg: ExcludeMethods<MasterPrompt>) {
    super(arg);

    this.id = arg.id;
    this.prompt = arg.prompt;
  }

  static create(
    args: Omit<ConstructorParameters<typeof MasterPrompt>[0], "id">,
  ) {
    return new MasterPrompt({
      id: v4(),
      ...args,
    });
  }
}
