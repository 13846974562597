import { v4 } from "uuid";

import { PermittedUserRepository } from "@spesill/repository/db/PermittedUser.repository";

import { Entity } from "./__common__/entity";
import { User } from "./user";

export type UserPermissionType = "editor" | "viewer";
export type UserWithPermission = User & { permission: UserPermissionType };

export class PermittedUser extends Entity {
  id: string; // 重複を防ぐためにuserIdをキーにする
  permission: UserPermissionType;

  constructor(arg: ExcludeMethods<PermittedUser>) {
    super(arg);

    this.id = arg.id;
    this.permission = arg.permission;
  }

  static create(
    args: Omit<ConstructorParameters<typeof PermittedUser>[0], "id">,
  ) {
    return new PermittedUser({
      id: v4(),
      ...args,
    });
  }
  static async updatePermittedUsers(
    newPermittedUsers: UserWithPermission[],
    documentId: string,
  ) {
    const permittedUserRepository = new PermittedUserRepository();
    // 既存の権限を取得し削除する必要のある権限を特定
    const existingPermissions =
      await permittedUserRepository.findByDocumentId(documentId);
    // 権限削除
    const usersToDelete = existingPermissions.filter(
      (user) => !newPermittedUsers.some((newUser) => newUser.id === user.id),
    );

    for (const userToDelete of usersToDelete) {
      await permittedUserRepository.deleteById(documentId, userToDelete.id);
    }
    // 権限追加更新
    for (const userToAddOrUpdate of newPermittedUsers) {
      await permittedUserRepository.add(documentId, userToAddOrUpdate.id, {
        permission: userToAddOrUpdate.permission,
      });
    }
  }
}
