import { v4 } from "uuid";

import { Entity } from "./__common__/entity";

export type aiCounterFieldType = keyof Pick<
  TenantAiUsage,
  | "generateSpec"
  | "generateSpecHeadings"
  | "generateVectorIndices"
  | "evaluateSpec"
  | "aiChat"
  | "generatePunchImage"
  | "generatePunchImageInDetail"
  | "extractFlowchartFormat"
  | "generateExcelText"
  | "generateTextFromSheets"
  | "refineText"
>;

export class TenantAiUsage extends Entity {
  id: string; // 月毎に一意にするためidはtenantId+targetMonthDate.toISOString()にする
  targetMonthDate: Date;
  generateSpec: number;
  generateSpecHeadings: number;
  generateVectorIndices: number;
  evaluateSpec: number;
  aiChat?: number;
  generatePunchImage?: number;
  generatePunchImageInDetail?: number;
  extractFlowchartFormat?: number;
  generateExcelText?: number;
  generateTextFromSheets?: number;
  refineText?: number;
  tenantId: string;

  constructor(arg: ExcludeMethods<TenantAiUsage>) {
    super(arg);

    this.id = arg.id;
    this.targetMonthDate = arg.targetMonthDate;
    this.generateSpec = arg.generateSpec;
    this.generateSpecHeadings = arg.generateSpecHeadings;
    this.generateVectorIndices = arg.generateVectorIndices;
    this.evaluateSpec = arg.evaluateSpec;
    this.aiChat = arg.aiChat;
    this.tenantId = arg.tenantId;
  }

  static create(
    args: Omit<ConstructorParameters<typeof TenantAiUsage>[0], "id"> & {
      tenantId: string;
    },
  ) {
    return new TenantAiUsage({
      id: v4(),
      ...args,
    });
  }

  totalCount(): number {
    return (
      this.generateSpec +
      this.generateSpecHeadings +
      this.generateVectorIndices +
      this.evaluateSpec +
      (this.aiChat ?? 0) +
      (this.generatePunchImage ?? 0) +
      (this.generatePunchImageInDetail ?? 0) +
      (this.extractFlowchartFormat ?? 0) +
      (this.generateExcelText ?? 0) +
      (this.generateTextFromSheets ?? 0) +
      (this.refineText ?? 0)
    );
  }
}
