import { toast } from "react-toastify";
import { v4 } from "uuid";

import { privateStorage } from "@spesill/config/firebase";
import { deleteObject, listAll, ref } from "@spesill/libs/firebase";
import { LearningDocument, PostsNotification, User } from "@spesill/models";
import { DocumentRepository } from "@spesill/repository/db/document.repository";
import { LearningDatabaseRepository } from "@spesill/repository/db/learningDatabase.repository";
import { LearningDocumentRepository } from "@spesill/repository/db/learningDocument.repository";

import { Entity } from "./__common__/entity";

export class LearningDatabase extends Entity {
  id: string;
  tenantId: string;
  systemName: string;
  createUserId: string;
  lastUpdateUserId: string;
  deletedAt?: Date;
  type?: "DEFAULT" | "CUSTOM";
  structureType: "unstructured" | "table";

  constructor(arg: ExcludeMethods<LearningDatabase>) {
    super(arg);

    this.id = arg.id;
    this.tenantId = arg.tenantId;
    this.systemName = arg.systemName;
    this.createUserId = arg.createUserId;
    this.lastUpdateUserId = arg.lastUpdateUserId;
    this.deletedAt = arg.deletedAt;
    this.type = arg.type;
    this.structureType = arg.structureType;
  }

  static create(
    args: Omit<ConstructorParameters<typeof LearningDatabase>[0], "id">,
  ) {
    return new LearningDatabase({
      id: v4(),
      deletedAt: undefined,
      ...args,
    });
  }

  static async deleteWithLearningDocuments({
    learningDatabase,
    user,
  }: {
    learningDatabase: LearningDatabase;
    user: User;
  }) {
    try {
      const path = `tenants/${learningDatabase.tenantId}/vector_indices/learnings/${learningDatabase.id}`;
      try {
        listAll(ref(privateStorage, path))
          .then((res) => res.items)
          .then((items) => {
            items.forEach((item) => {
              deleteObject(item);
            });
          });
      } catch (error) {
        // NOTE: vector_indicesが存在しない場合はエラーになるが、無視してかまわない
        console.error(error);
      }

      const learningDatabaseRepository = new LearningDatabaseRepository();
      const learningDocuments = await learningDatabase.learningDocuments();

      await Promise.all(
        learningDocuments.map(async (learningDocument) => {
          await LearningDocument.deleteWithStorage({
            learningDocument,
          });
        }),
      );
      learningDatabaseRepository.deleteById(learningDatabase.id);

      const documentRepository = new DocumentRepository();

      const documents = await learningDatabase.documents();
      await Promise.all(
        documents.map(async (document) => {
          documentRepository.updateById(document.id, {
            ...document,
            learningDatabaseId: null,
          });
        }),
      );
      PostsNotification.insertNewNotification({
        resource: learningDatabase,
        createUser: user,
        action: "delete",
      });
    } catch (error) {
      console.error(error);
      error instanceof Error && toast.error(error.message);
    }
  }

  async learningDocuments() {
    const learningDocumentRepository = new LearningDocumentRepository();
    const learningDocuments =
      await learningDocumentRepository.findByTenantIdAndLearningDatabaseId(
        this.tenantId,
        this.id,
      );
    return learningDocuments;
  }

  async documents() {
    const documentRepository = new DocumentRepository();
    const documents = await documentRepository.findByTenantId(this.tenantId, {
      learningDatabaseId: this.id,
    });
    return documents;
  }
}
