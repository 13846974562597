import { v4 } from "uuid";

import {
  doc,
  collection,
  getDocs,
  query,
  setDoc,
  where,
  QueryConstraint,
  orderBy,
} from "@spesill/libs/firebase";
import { PostsNotification } from "@spesill/models";

import { DBRepositoryBase } from "./__common__/DBRepositoryBase";

export class PostsNotificationRepository extends DBRepositoryBase<
  ExcludeMethods<PostsNotification>
> {
  private PATH = "postsNotifications";

  add = async (
    postsNotification: Omit<
      ExcludeMethods<PostsNotification>,
      "id" | "createdAt" | "updatedAt"
    >,
  ) => {
    const now = new Date();
    const docId = v4();
    const newPage = {
      ...postsNotification,
      createdAt: now,
      updatedAt: now,
      id: undefined,
    };

    await setDoc(doc(this.db, this.PATH, docId), this.objectToDoc(newPage));

    return new PostsNotification({ ...newPage, id: docId });
  };

  findByTenantId = async (tenantId: string) => {
    const queryConstraints: QueryConstraint[] = [
      where("tenantId", "==", tenantId),
      orderBy("createdAt", "desc"),
    ];
    const q = query(collection(this.db, this.PATH), ...queryConstraints);
    const docs = await getDocs(q).then((res) => res.docs);

    if (!docs) {
      throw new Error("見つかりませんでした");
    }

    return docs.map((doc) => new PostsNotification(this.docToObject(doc)));
  };

  findByIds = async (ids: string[]) => {
    if (ids.length === 0) return [];
    const queryConstraints: QueryConstraint[] = [where("__name__", "in", ids)];
    const q = query(collection(this.db, this.PATH), ...queryConstraints);
    const docs = await getDocs(q).then((res) => res.docs);

    if (!docs) {
      throw new Error("見つかりませんでした");
    }

    return docs.map((doc) => new PostsNotification(this.docToObject(doc)));
  };
}
