import { v4 } from "uuid";

import {
  doc,
  collection,
  getDocs,
  query,
  setDoc,
  where,
  QueryConstraint,
  getDoc,
  updateDoc,
  orderBy,
  limit,
} from "@spesill/libs/firebase";
import { User, UsersPostsNotificationStatus } from "@spesill/models";

import { DBRepositoryBase } from "./__common__/DBRepositoryBase";

export class UsersPostsNotificationStatusRepository extends DBRepositoryBase<
  ExcludeMethods<UsersPostsNotificationStatus>
> {
  private PATH = "usersPostsNotificationStatus";

  add = async (
    usersPostsNotificationStatus: Omit<
      ExcludeMethods<UsersPostsNotificationStatus>,
      "id" | "createdAt" | "updatedAt" | "readAt"
    >,
  ) => {
    const docId = v4();
    const now = new Date();
    const newPage = {
      ...usersPostsNotificationStatus,
      createdAt: now,
      updatedAt: now,
      readAt: null,
      id: undefined,
    };

    await setDoc(doc(this.db, this.PATH, docId), this.objectToDoc(newPage));

    return new UsersPostsNotificationStatus({ ...newPage, id: docId });
  };

  update = async (
    usersPostsNotificationStatus: ExcludeMethods<UsersPostsNotificationStatus>,
  ) => {
    const ref = doc(this.db, this.PATH, usersPostsNotificationStatus.id);
    const usersPostsNotificationStatusData = await getDoc(ref);

    if (!usersPostsNotificationStatusData.exists()) {
      throw new Error("ドキュメントが見つかりませんでした");
    } else {
      await updateDoc(ref, {
        ...this.objectToDoc(usersPostsNotificationStatus),
        id: usersPostsNotificationStatus.id,
        createdAt: usersPostsNotificationStatus.createdAt,
        updatedAt: new Date(),
      });
      return { id: usersPostsNotificationStatus.id };
    }
  };

  findByUserNotificationId = async (
    tenantId: string,
    useId: string,
    postsNotificationId: string,
  ) => {
    const queryConstraints: QueryConstraint[] = [
      where("tenantId", "==", tenantId),
      where("userId", "==", useId),
      where("postsNotificationId", "==", postsNotificationId),
    ];
    const q = query(collection(this.db, this.PATH), ...queryConstraints);
    const docs = await getDocs(q).then((res) => res.docs);

    if (!docs[0]) {
      throw new Error("見つかりませんでした");
    }
    return new UsersPostsNotificationStatus(this.docToObject(docs[0]));
  };

  findByTenantId = async (tenantId: string) => {
    const queryConstraints: QueryConstraint[] = [
      where("tenantId", "==", tenantId),
    ];
    const q = query(collection(this.db, this.PATH), ...queryConstraints);
    const docs = await getDocs(q).then((res) => res.docs);

    if (!docs) {
      throw new Error("見つかりませんでした");
    }

    return docs.map(
      (doc) => new UsersPostsNotificationStatus(this.docToObject(doc)),
    );
  };

  findByUserId = async (user: User) => {
    const queryConstraints: QueryConstraint[] = [
      where("tenantId", "==", user.tenantId),
      where("userId", "==", user.id),
      orderBy("createdAt", "desc"),
      limit(30), // FirebaseError'IN' supports up to 30 comparison values.を防ぐため
    ];
    const q = query(collection(this.db, this.PATH), ...queryConstraints);
    const docs = await getDocs(q).then((res) => res.docs);

    if (!docs) {
      throw new Error("見つかりませんでした");
    }

    return docs.map(
      (doc) => new UsersPostsNotificationStatus(this.docToObject(doc)),
    );
  };

  findByNotificationAndUserId = async ({
    userId,
    postsNotificationIds,
  }: {
    userId: string;
    postsNotificationIds: string[];
  }) => {
    const queryConstraints: QueryConstraint[] = [
      where("userId", "==", userId),
      where("postsNotificationId", "in", postsNotificationIds),
    ];
    const q = query(collection(this.db, this.PATH), ...queryConstraints);
    const docs = await getDocs(q).then((res) => res.docs);

    if (!docs) {
      throw new Error("見つかりませんでした");
    }

    return docs.map(
      (doc) => new UsersPostsNotificationStatus(this.docToObject(doc)),
    );
  };

  findByUsersUnreadPostsNotification = async (
    user: User,
    maxLimit?: number,
  ) => {
    const queryConstraints: QueryConstraint[] = [
      where("tenantId", "==", user.tenantId),
      where("userId", "==", user.id),
      where("readAt", "==", null),
    ];
    if (maxLimit) {
      queryConstraints.push(limit(maxLimit));
    }
    const q = query(collection(this.db, this.PATH), ...queryConstraints);
    const docs = await getDocs(q).then((res) => res.docs);
    if (!docs) {
      throw new Error("見つかりませんでした");
    }

    return docs.map(
      (doc) => new UsersPostsNotificationStatus(this.docToObject(doc)),
    );
  };
}
