import { v4 } from "uuid";

import { Entity } from "./__common__/entity";

export class NewUserInvitation extends Entity {
  tenantId: string;
  receiverUserId: string;
  senderUserId: string;

  constructor(arg: ExcludeMethods<NewUserInvitation>) {
    super(arg);

    this.tenantId = arg.tenantId;
    this.receiverUserId = arg.receiverUserId;
    this.senderUserId = arg.senderUserId;
  }

  static create(
    args: Omit<ConstructorParameters<typeof NewUserInvitation>[0], "id">,
  ) {
    return new NewUserInvitation({
      id: v4(),
      ...args,
    });
  }
}
