import { v4 } from "uuid";

import {
  doc,
  collection,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
  deleteDoc,
  updateDoc,
} from "@spesill/libs/firebase";
import { PunchImage } from "@spesill/models/punchImage";

import { DBRepositoryBase } from "./__common__/DBRepositoryBase";

export class PunchImageRepository extends DBRepositoryBase<
  ExcludeMethods<PunchImage>
> {
  private PATH = "punchImages";

  add = async (
    document: Omit<
      ExcludeMethods<PunchImage>,
      "id" | "createdAt" | "updatedAt"
    >,
  ) => {
    const docId = v4();
    const newPage = {
      ...document,
      createdAt: new Date(),
      updatedAt: new Date(),
      id: undefined,
      deletedAt: undefined,
    }; // idをobjectに詰めるとdocIdとは別にidというフィールドができてしまうためundefinedに

    await setDoc(doc(this.db, this.PATH, docId), this.objectToDoc(newPage));

    return new PunchImage({ ...newPage, id: docId });
  };

  findById = async (id: string) => {
    const ref = doc(this.db, this.PATH, id);
    const document = await getDoc(ref);

    if (!document.exists()) {
      throw new Error("ドキュメントが見つかりませんでした");
    } else {
      return new PunchImage(this.docToObject(document));
    }
  };

  updateById = async (
    id: string,
    punch: Omit<ExcludeMethods<PunchImage>, "id" | "createdAt" | "updatedAt">,
  ) => {
    const ref = doc(this.db, this.PATH, id);
    const documentData = await getDoc(ref);

    if (!documentData.exists()) {
      throw new Error("ドキュメントが見つかりませんでした");
    } else {
      await updateDoc(ref, {
        ...this.objectToDoc(punch),
        updatedAt: new Date(),
      });
      return new PunchImage(this.docToObject(documentData));
    }
  };

  findByTenantIdAndId = async (tenantId: string, id: string) => {
    const q = query(
      collection(this.db, this.PATH),
      where("tenantId", "==", tenantId),
      where("__name__", "==", id),
    );
    const docs = await getDocs(q).then((res) => res.docs);

    if (!docs[0]) {
      throw new Error("ドキュメントが見つかりませんでした");
    }
    return new PunchImage(this.docToObject(docs[0]));
  };

  findAll = async ({ tenantId }: { tenantId: string }) => {
    const q = query(
      collection(this.db, this.PATH),
      where("tenantId", "==", tenantId),
    );
    const docs = await getDocs(q).then((res) => res.docs);

    return docs.map((doc) => new PunchImage(this.docToObject(doc)));
  };

  deleteById = async (id: string) => {
    const ref = doc(this.db, this.PATH, id);
    const document = await getDoc(ref);
    // 論理削除を実装する場合条件に追加 → || !document.data()?.deletedAt
    if (!document.exists()) {
      throw new Error("ドキュメントが見つかりませんでした");
    } else {
      await deleteDoc(ref);
    }
  };
}
