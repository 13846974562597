import { v4 } from "uuid";

import { Entity } from "./__common__/entity";

export class SharedDocument extends Entity {
  id: string;
  tenantId: string;
  documentId: string;
  publisherId: string;
  password: string;
  expireDate: Date;

  constructor(arg: ExcludeMethods<SharedDocument>) {
    super(arg);

    this.id = arg.id;
    this.tenantId = arg.tenantId;
    this.documentId = arg.documentId;
    this.publisherId = arg.publisherId;
    this.password = arg.password;
    this.expireDate = arg.expireDate;
  }

  static create(
    args: Omit<ConstructorParameters<typeof SharedDocument>[0], "id">,
  ) {
    return new SharedDocument({
      id: v4(),
      ...args,
    });
  }
}
