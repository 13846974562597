export const host = () => {
  switch (process.env.NEXT_PUBLIC_APP_ENV) {
    case "localhost":
      return "http://localhost:8080";
    case "staging":
      return "https://staging.spesill.jp";
    case "production":
      return "https://spesill.jp";
    default:
      throw Error("Unknown APP_ENV");
  }
};

export const spesillAIBaseURL = () => {
  switch (process.env.NEXT_PUBLIC_APP_ENV) {
    case "localhost":
      return "http://localhost:3000";
    case "staging":
      return "https://spesill-ai-oyikhp3a2a-an.a.run.app";
    case "production":
      return "https://spesill-ai-mhdsq7gsbq-an.a.run.app";
    default:
      throw Error("Unknown APP_ENV");
  }
};

export const appEnv = () => {
  if (process.env.NEXT_PUBLIC_APP_ENV)
    return process.env.NEXT_PUBLIC_APP_ENV as
      | "localhost"
      | "staging"
      | "production";
  else {
    throw Error("Unknown APP_ENV");
  }
};

export const isLocalhost = () => appEnv() === "localhost";
export const isStaging = () => appEnv() === "staging";
export const isProduction = () => appEnv() === "production";
