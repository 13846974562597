import {
  FirebaseOptions,
  getAuth,
  getStorage,
  initializeApp,
  initializeFirestore,
} from "@spesill/libs/firebase";

import { isLocalhost, isProduction, isStaging } from "./env";

let firebaseConfig: FirebaseOptions;

if (isLocalhost() || isStaging()) {
  firebaseConfig = {
    apiKey: "AIzaSyAlMHoSOeA6iPiqW4QWKcdABbjS_CM4xUw",
    authDomain: "spesill-staging.firebaseapp.com",
    projectId: "spesill-staging",
    storageBucket: "spesill-staging.appspot.com",
    messagingSenderId: "649815334136",
    appId: "1:649815334136:web:712e910d0d94f72efd2f0d",
    measurementId: "G-YF36H4QVTG",
  };
} else if (isProduction()) {
  // TODO:
  firebaseConfig = {
    apiKey: "AIzaSyCsAwKUhn520HS3G6AOy4GrIxJAKTbWll8",
    authDomain: "spesill-production.firebaseapp.com",
    projectId: "spesill-production",
    storageBucket: "spesill-production.appspot.com",
    messagingSenderId: "771450906818",
    appId: "1:771450906818:web:517b84e2cd274142fdb35b",
    measurementId: "G-W89GS1Y4YV",
  };
} else {
  throw new Error("Unknown APP_ENV");
}

export const app = initializeApp(firebaseConfig);
// TODO: GA導入
// export const analytics = getAnalytics(app);
export const auth = getAuth(app);

const getPrivateStorage = () => {
  if (isLocalhost() || isStaging()) {
    return "gs://spesill-staging_private";
  } else if (isProduction()) {
    return "gs://spesill-production_private";
  } else {
    throw new Error("Unknown APP_ENV");
  }
};
export const privateStorage = getStorage(app, getPrivateStorage());
export const db = initializeFirestore(app, { ignoreUndefinedProperties: true });
